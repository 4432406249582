import { t } from '@/i18n/translation_util';

import { defaultTrialDays } from '@/lib/promo-codes';
import { useCheckoutStore } from '../check-out.hooks';
export function PricingLabel1() {
  const paymentPlans = useCheckoutStore((s) => s.planOptions);
  return (
    <div className="text-center text-[16px] leading-[21px] text-[#4f4f4f] sm:text-[20px] sm:leading-[27px]">
      <div
        dangerouslySetInnerHTML={{
          __html:
            'monthly' in paymentPlans
              ? t(
                  'Try unlimited listening. After trialDays its priceMonthly or priceYearly',
                  {
                    trialDays: defaultTrialDays,
                    priceMonthly: paymentPlans.monthly.amount,
                    priceYearly: paymentPlans.yearly.amount,
                  },
                )
              : t('Try unlimited listening. After trialDays its priceYearly', {
                  trialDays: defaultTrialDays,
                  priceYearly: paymentPlans.yearly.amount,
                }),
        }}
      />
    </div>
  );
}
